<template>
  <div>
    <loading v-if="Loading"/>
    <!-- Tabel -->
    <b-row v-if="!Loading">
      <b-col class="col-12">
        <b-table striped hover :items="tableData" :fields="fields" :per-page="perPage" :current-page="currentPage">
          <template v-slot:cell(action)="row">
            <b-button @click="approveList(row.item)" variant="primary" size="sm">Action</b-button>
          </template>
        </b-table>
        <br/>
        <div class="d-flex justify-content-end">
          <b-pagination v-model="currentPage" :total-rows="tableData.length" :per-page="perPage"></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    axios
  },
  data() {
    return {
      Loading: false,
      perPage: 10, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
      fields: [
        {key:"identifier",label:"Steam Hash"},
        {key:"fullname",label:"Nama Lengkap"},
        {key:"username",label:"Username"},
        {key:"role_name",label:"Role"},
        {key:"action",label:"Action"},
      ],
      modalShow: false,
      modalShowRole: false,
      filter: null,
      formUser: _interfaces.master_user,
      tableData: [],
      dataWarga: [],
      dataRole: [],
      isEdit: false,
    };
  },
  methods: {
    init(){
      this.isEdit = false;
      this.getData();
    },
    showModal() {
      this.isEdit = false;
      this.modalShow = true;
    },
    getData() {
      axios.post("idp/master/registration/list",{id:null}).then((response) => {
        this.tableData = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });
    },
    approveList(row) {

      this.$swal({
        title: "Approve User?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Approve",
        cancelButtonText: "Reject",
      }).then((result) => {
        if (result.isConfirmed) {

          axios.post("idp/master/registration/approve",{id:row.id}).then((response) => {
            if(response.data.status == 2000){
              this.$swal({
                title:  "Approved",
                icon: "success",
                background: "black",
                showCancelButton: false,
                confirmButtonColor: "#7367f0",
                cancelButtonColor: "#ea5455",
                confirmButtonText: "ok",
              });
            }
          }).catch((error) => {
            if (error.status === 4000) {
              localStorage.clear();
              this.$router.push({ path: "/login" });
              ({
                title: "Login Expired",
                message: "Sesi Login Habis",
              });
            }
          }).finally(() => {
            this.Loading = false;
            this.modalShow = false;
            this.init();
          });
        } else {
          axios.post("idp/master/registration/reject",{id:row.id}).then((response) => {
            if(response.data.status == 2000){
              this.$swal({
                title: "Rejected",
                icon: "warning",
                background: "black",
                showCancelButton: false,
                confirmButtonColor: "#7367f0",
                cancelButtonColor: "#ea5455",
                confirmButtonText: "ok",
              });
            }
          }).catch((error) => {
            if (error.status === 4000) {
              localStorage.clear();
              this.$router.push({ path: "/login" });
              ({
                title: "Login Expired",
                message: "Sesi Login Habis",
              });
            }
          }).finally(() => {
            this.Loading = false;
            this.modalShow = false;
            this.init();
          });
        }
      });
    },
    deleteUser(row){
      this.formUser.id = row.id
      this.$swal({
        title: "Hapus User?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Save",
      }).then((result) => {
        if (result.isConfirmed) {  
          this.Loading = true;
          setTimeout(() => {
            axios.post("idp/master/user/delete",this.formUser).then((response) => {
              this.$swal({
                title: "Sukses",
                icon: "success",
                background: "black",
                showCancelButton: false,
                confirmButtonColor: "#7367f0",
                cancelButtonColor: "#ea5455",
                confirmButtonText: "Save",
              });
            }).catch((error) => {
              if (error.status === 4000) {
                localStorage.clear();
                this.$router.push({ path: "/login" });
                ({
                  title: "Login Expired",
                  message: "Sesi Login Habis",
                });
              }
            }).finally(() => {
              this.Loading = false;
              this.modalAkses = false;
              this.init();
            });
          }, 1000);
        } else {
          this.modalAkses = false
        }
      });
    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>